import defaultSettings from '../settings.json';
import {isSSR} from '@/utils/is';
import storage from '@/utils/storage';
const defaultTheme = storage.getItem('arco-theme') || 'light';

function changeTheme(newTheme?: 'string') {
    if ((newTheme || defaultTheme) === 'dark') {
        document.body.setAttribute('arco-theme', 'dark');
    } else {
        document.body.removeAttribute('arco-theme');
    }
}

// init page theme
if (!isSSR) {
    changeTheme();
}

export interface GlobalState {
    theme?: string;
    settings?: typeof defaultSettings;
    userInfo?: {
        name?: string;
        avatar?: string;
        job?: string;
        organization?: string;
        location?: string;
        email?: string;
    };
}

const initialState: GlobalState = {
    theme: defaultTheme,
    settings: defaultSettings,
};

export default function store(state = initialState, action) {
    switch (action.type) {
        case 'toggle-theme': {
            const {theme} = action.payload;
            if (theme === 'light' || theme === 'dark') {
                localStorage.setItem('arco-theme', theme);
                changeTheme(theme);
            }

            return {
                ...state,
                theme,
            };
        }
        case 'update-settings': {
            const {settings} = action.payload;
            return {
                ...state,
                settings,
            };
        }
        case 'update-userInfo': {
            const {userInfo = {}, userLoading} = action.payload;
            return {
                ...state,
                userLoading,
                userInfo,
            };
        }
        default:
            return state;
    }
}
